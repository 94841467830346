<template>
  <div ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header bg1">
      <div class="path-left">
      </div>
      <!-- <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.1em">{{ weatherText }}</span>
      </div> -->
      <span style="flex: 1"></span>
      <div class="header-title p-clip">一立云运营监控中心</div>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>

      <div class="path-right">
        <img @click="SetFullscreen"
          src="@/assets/images/OperationBigScreen/DeploymentControl/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <project />
      <div class="MainOuter-configuration" @click="handleGoPath('/maintenanceConfiguration')">监控配置</div>
    </div>
  </div>
</template>
<script>
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import project from "@/views/OperationBigScreen/projectHall/components/project";
import dayjs from "dayjs";
import { mapMutations } from "vuex";

export default {
  // `name`与路由保持一致
  components: { project },
  data() {
    // this.intervalId = null;
    return {
      timeStr: "", // 时间
      timer: null, // 时间定时器
      loading: false, //加载
      weatherText: "", //天气说明
      weatherImg: "", //天气图标
      weatherArea: "", //天气地址
      vehicleTotal: 0,
      fullscreenchange: null,
      visibilitychange: null,
      backLoading: false,
      intervalId: null,
      dayNum: "",
      dateText: "",
      centerLng: null,
      zoom: 10,
      markerOffset: [0, 0],
      infoVisible: false, //
      currentVehicle: null,
      vehicleList: [
        {
          X: 116.40121,
          Y: 39.907795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "1",
        },
        {
          X: 116.40121,
          Y: 39.917795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "2",
        },
        {
          X: 116.40121,
          Y: 39.927795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "3",
        },
        {
          X: 116.40121,
          Y: 39.937795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "4",
        },
        {
          X: 116.40121,
          Y: 39.947795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "5",
        },
        {
          X: 116.40121,
          Y: 39.957795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "14",
        },
        {
          X: 116.40121,
          Y: 39.967795,
          Z: 0,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "6",
        },
        {
          X: 116.41121,
          Y: 39.907795,
          Z: 1,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "7",
        },
        {
          X: 116.41121,
          Y: 39.917795,
          Z: 1,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "8",
        },
        {
          X: 116.41121,
          Y: 39.927795,
          Z: 1,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "9",
        },
        {
          X: 116.41121,
          Y: 39.937795,
          Z: 2,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "10",
        },
        {
          X: 116.41121,
          Y: 39.947795,
          Z: 2,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "11",
        },
        {
          X: 116.41121,
          Y: 39.957795,
          Z: 3,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "12",
        },
        {
          X: 116.41121,
          Y: 39.967795,
          Z: 3,
          cp: "粤ET25V2",
          cz: "利星行项目",
          lx: "屈臂式高空作业平台",
          sm: "GDY00857",
          isWork: true,
          id: "13",
        },
      ],
      tabsIndex: 1,
      alarmTypeList: [
        {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon1.png"),
          label: "前向碰撞预警次数",
          value: "48003",
        },
        {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon2.png"),
          label: "AEB制动报警次数",
          value: "48003",
        },
        {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon3.png"),
          label: "车道偏离预警次数",
          value: "48003",
        },
        {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon4.png"),
          label: "车距检测预警次数",
          value: "48003",
        },
        {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon5.png"),
          label: "超速报警次数",
          value: "48003",
        },
      ],
      alarmDataList: {
        qxpzyj: [
          {
            X: 116.40121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "1",
          },
          {
            X: 116.50121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "2",
          },
          {
            X: 116.40121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "3",
          },
          {
            X: 116.46121,
            Y: 39.607795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "4",
          },
          {
            X: 111.40121,
            Y: 39.9307795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "5",
          },
          {
            X: 116.42121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "6",
          },
          {
            X: 116.43121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "7",
          },
          {
            X: 116.46121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "8",
          },
          {
            X: 116.47121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "9",
          },
          {
            X: 116.48121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "10",
          },
        ],
        zdbj: [
          {
            X: 116.50121,
            Y: 39.927795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "11",
          },
          {
            X: 116.51121,
            Y: 39.937795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "12",
          },
          {
            X: 116.40121,
            Y: 39.607795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "13",
          },
          {
            X: 116.36121,
            Y: 39.407795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "14",
          },
          {
            X: 111.40121,
            Y: 39.3307795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "15",
          },
          {
            X: 116.22121,
            Y: 39.207795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "16",
          },
          {
            X: 116.47121,
            Y: 39.977795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "17",
          },
          {
            X: 116.48121,
            Y: 39.987795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "18",
          },
          {
            X: 116.49121,
            Y: 39.997795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "19",
          },
          {
            X: 116.58121,
            Y: 39.997795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "20",
          },
        ],
        cdpl: [
          {
            X: 116.55121,
            Y: 39.127795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "21",
          },
          {
            X: 116.51121,
            Y: 39.207795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "22",
          },
          {
            X: 116.12121,
            Y: 39.427795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "23",
          },
          {
            X: 116.64121,
            Y: 39.607795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "24",
          },
          {
            X: 111.56121,
            Y: 39.9307795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "25",
          },
          {
            X: 116.42121,
            Y: 39.667795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "26",
          },
          {
            X: 116.43121,
            Y: 39.307795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "27",
          },
          {
            X: 116.46121,
            Y: 39.587795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "28",
          },
          {
            X: 116.462121,
            Y: 39.927795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "29",
          },
          {
            X: 116.50121,
            Y: 39.507795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "30",
          },
        ],
        cjjc: [
          {
            X: 116.68121,
            Y: 39.627795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "31",
          },
          {
            X: 116.12121,
            Y: 39.937795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "32",
          },
          {
            X: 116.40121,
            Y: 39.127795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "33",
          },
          {
            X: 116.46121,
            Y: 39.457795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "34",
          },
          {
            X: 111.41121,
            Y: 39.9207795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "35",
          },
          {
            X: 116.42121,
            Y: 39.547795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "36",
          },
          {
            X: 116.43121,
            Y: 39.767795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "37",
          },
          {
            X: 116.46121,
            Y: 39.727795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "38",
          },
          {
            X: 116.47121,
            Y: 39.237795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "49",
          },
          {
            X: 116.48121,
            Y: 39.357795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "40",
          },
        ],
        csbj: [
          {
            X: 116.45121,
            Y: 39.957795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "41",
          },
          {
            X: 116.99121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "42",
          },
          {
            X: 116.40121,
            Y: 39.907795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "43",
          },
          {
            X: 116.41121,
            Y: 39.607795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "44",
          },
          {
            X: 111.41121,
            Y: 39.927795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "45",
          },
          {
            X: 116.42121,
            Y: 39.327795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "46",
          },
          {
            X: 116.43121,
            Y: 39.687795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "47",
          },
          {
            X: 116.46121,
            Y: 39.477795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "48",
          },
          {
            X: 116.47121,
            Y: 39.567795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "49",
          },
          {
            X: 116.41121,
            Y: 39.917795,
            Z: 0,
            cp: "粤ET25V2",
            cz: "利星行项目",
            lx: "屈臂式高空作业平台",
            sm: "GDY00857",
            isWork: true,
            id: "50",
          },
        ],
      },
      alarmNameList: [
        {
          valueKey: 'qxpzyj',
          pointRender: (e) => { this.handleSetPointRender(e, 1) }
        },
        {
          valueKey: 'zdbj',
          pointRender: (e) => { this.handleSetPointRender(e, 0) }
        },
        {
          valueKey: 'cdpl',
          pointRender: (e) => { this.handleSetPointRender(e, 2) }
        },
        {
          valueKey: 'cjjc',
          pointRender: (e) => { this.handleSetPointRender(e, 3) }
        },
        {
          valueKey: 'csbj',
          pointRender: (e) => { this.handleSetPointRender(e, 4) }
        },
      ]
    };
  },
  created() {},
  // mounted
  activated() {
    let that = this;
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = dayjs().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = dayjs(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { alarmDataList } = this;
      for (const key in alarmDataList) {
        alarmDataList[key] = alarmDataList[key].map((obj, indd) => {
          const point = GPS.delta(obj.Y, obj.X);
          return {
            key: obj.key || indd,
            lng: point.lon,
            lat: point.lat,

            // lng: 116.40121,
            // lat: 39.907795,

            data: obj,
          };
        })
      }
      return alarmDataList;
    },
    // 聚合点
    clusterPoints2() {
      const { vehicleList } = this;
      return vehicleList.map((obj, indd) => {
        const point = GPS.delta(obj.Y, obj.X);
        return {
          key: obj.key || indd,
          lng: point.lon + 0.02,
          lat: point.lat,
          data: obj,
        };
      });
    },
    windowPoint() {
      // this.infoVisible = true;
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle) {
        this.infoVisible = false;
        return [];
      }
      const point = GPS.delta(currentVehicle.Y, currentVehicle.X);

      return [point.lon, point.lat];
    },
  },

  methods: {
    async init() {},
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      // this.intervalId = setTimeout(async () => {
      //   await this.init();
      //   clearTimeout(this.intervalId);
      //   if (this.fullscreenchange) {
      //     this.handlePolling();
      //   }
      // }, 1000 * 60);
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs["BigScreenDisplay"];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },

    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    handleGoPath(path) {
      if (!path) {
        this.$router.go(-1);
      }
      this.$router.push({
        path,
      });
    },
    onMapLoad(e) {
      console.log(e, "---地图加载完成---");
    },
    onMarkerClick(e) {
      this.infoVisible = true;
      this.currentVehicle = e.data;
      console.log(e, "---点击标点---");
    },
    vehicleIconName(val, current) {
      return require(`../../../assets/images/OperationBigScreen/MapEvents/marker-icon-${current}.png`);
    },
    handlePointRender(context) {
      this.handleSetPointRender(context, 0);
    },
    handlePointRender1(context) {
      this.handleSetPointRender(context, 2);
    },
    handleSetPointRender(context, type) {
      const size = 50;
      let div = document.createElement("div");
      div.style.backgroundImage = `url(https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/bigScreenUI/mapui/clusterer-${type}.png)`;
      div.style.backgroundSize = "100%";
      div.style.width = "56px";
      div.style.height = "62px";
      div.innerHTML = context.count;
      div.style.lineHeight = "62px";
      div.style.color = "#fff";
      div.style.fontSize = "16px";
      div.style.fontWeight = 500;
      div.style.textAlign = "center";
      context.marker.setOffset(new AMap.Pixel(-56 / 2, -62 / 2));
      context.marker.setContent(div);
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
::v-deep .amap-maptypecontrol {
  display: none;
}
::v-deep .amap-controls {
  display: none;
}
::v-deep .WindowContent {
  background-color: transparent;
  border: none;
}
::v-deep .AnchorArrow {
  display: none;
}
</style>
