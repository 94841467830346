<template>
  <div class="project" ref="project-box" v-loading="loading">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item :name="index" v-for="item,index in projectList" :key="index">
        <template slot="title">
          <div class="project-title">
            {{ item.title }} <div>{{ activeNames.includes(index) ? '收起' : '展开'}}</div>
          </div>
        </template>
        <div class="project-content">
          <div class="project-content-item" v-for="pItem,pIndex in item.list" :key="pIndex">
            <div class="project-content-item-parent" @click="handleGoPath(pItem)">
              <div class="project-content-item-parent-num">
                <div>{{ pItem.lsProjectName }}</div>
                <div class="project-content-item-parent-num-v">{{ pItem.vehicleCount }}</div>
              </div>
            </div>
            <div class="project-content-item-children" :style="{height: (pItem.isFolding ? 110 : Math.ceil((pItem.projectLsSonList ? pItem.projectLsSonList.length : 0) / maxBoxNum) * 126 - 16) + 'px'}">
              <div class="project-content-item-children-item" v-for="cItem,cIndex in pItem.projectLsSonList" :key="cIndex" @click="handleGoPath(cItem)">
                <div class="project-content-item-children-item-name">{{ cItem.lsProjectName || cItem.sonProjectName }}</div>
                <div class="project-content-item-children-item-v">{{ cItem.vehicleCount }}</div>
              </div>
            </div>
            <div class="project-content-item-folding" v-if="pItem.projectLsSonList && pItem.projectLsSonList.length  > maxBoxNum" @click="pItem.isFolding = !pItem.isFolding">{{ pItem.isFolding ? '展开' : '收起' }} <i class="el-icon-arrow-up" :style="{ transform: `rotate(${pItem.isFolding ? 180 : 0}deg)` }"></i>
 </div>
          </div>
          <empty v-if="!item.list || !item.list.length" text="暂无监控项目" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { projectLSList } from "@/api/riskData.js";
import empty from "@/views/OperationBigScreen/DeploymentControl/components/empty";
export default {
  props: {},
  data() {
    return {
      activeNames: [0],
      projectList: [
        {
          title: '商用车',
          list: [
            {
              name: '一级项目',
              isFolding: false,
              children: [
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
              ]
            }
          ]
        },
        {
          title: '工程机械',
          list: [
            {
              name: '一级项目',
              isFolding: false,
              children: [
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
                {
                  name: '广东睿隆车队监控'
                },
              ]
            }
          ]
        },
      ],
      boxWidth: 0,
      maxBoxNum: 0,
      loading: false,
    }
  },
  components: { empty },
  created() {
    this.handleResize();
    this.getList();
  },
  mounted() {
    // 添加事件监听
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleChange(e) {
      console.log(e);
    },
    handleGoPath(e) {
      this.$router.push({
        path: '/SCDeploymentControl?project=' + JSON.stringify(e)
      });
    },
    handleResize() {
      this.$nextTick(()=>{
        this.boxWidth = this.$refs['project-box'].offsetWidth - 428;
        this.maxBoxNum = Math.floor((this.boxWidth + 16) / 251);
      })
    },
    async getList() {
      this.loading = true;
      let {data,code} = await projectLSList({
        level: '',
        type: ''
      });
      this.loading = false;
      if (code == 200) {
        this.projectList[0].list = data.records.filter(r => r.type == 1);
        this.projectList[1].list = data.records.filter(r => r.type == 2);
        console.log(this.projectList);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.project {
  // display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 12px 25px;
  ::v-deep .el-collapse {
    border: none;
    .el-collapse-item__wrap {
      background-color: transparent;
      border: none;
    }
    .el-collapse-item__header {
      border: none;
      background-color: transparent;
    }
    .el-collapse-item__arrow {
      display: none;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    >div {
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin-left: 24px;
    }
  }
  &-content {
    padding-top: 10px;
    &-item {
      position: relative;
      display: flex;
      &-parent {
        width: 362px;
        height: 110px;
        padding-top: 42px;
        padding-left: 78px;
        margin-right: 16px;
        background-image: url('../../../../assets/images/OperationBigScreen/projectHall/parent.png');
        background-size: 100% 100%;
        cursor: pointer;
        &-num {
          display: inline-block;
          font-size: 20px;
          color: #68E7F1;
          line-height: 20px;
          &-v {
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            margin-top: 10px;
          }
          @supports (-webkit-background-clip: text) {
            .project-content-item-parent-num-v {
              background-image: -webkit-linear-gradient(#fff, #C0ECFE);
              background-image: linear-gradient(#fff, #C0ECFE);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              color: transparent;
            }
          }
        }
      }
      &-children {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
        gap: 16px;
        overflow: hidden;
        transition: all 0.3s;
        &-item {
          width: 235px;
          height: 110px;
          text-align: center;
          background-image: url('../../../../assets/images/OperationBigScreen/projectHall/children.png');
          background-size: 100% 100%;
          cursor: pointer;
          &-name {
            font-weight: 400;
            font-size: 18px;
            color: #3390FF;
            margin-top: 34px;
            line-height: 18px;
          }
          &-v {
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            margin-top: 18px;
          }
        }
      }
      &-folding {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        .el-icon-arrow-up {
          transition: all 0.3s;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>