<template>
  <div class="empty">
    <img src="../../../../assets/images/OperationBigScreen/empty-icon.png" alt="" srcset="">
    <div>{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "暂无数据",
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  width: 85px;
  color: #fff;
  text-align: center;
  margin: auto;
  img {
    width: 85px;
    height: 85px;
    margin-bottom: 14px;
  }
}
</style>